import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useSelector, useDispatch } from 'react-redux'
import * as R from 'ramda'
import InputMask from 'react-input-mask'

import { themeLens, brandLens, settingsLens } from '~/State/ducks/profile'
import { actions } from '~/State/ducks/leads'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'
import { idLens } from '~/State/ducks/domain'
import URL from '~/Utils/api_base'

const ContactContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
`

const TextContainer = styled.div`
  display: flex;
  color: ${({ color }) => `${color}` || `inherit`};
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme === 'Theme9' && `width: 100%`};
`

const DecoratedText = styled(Typography)`
  overflow: hidden;
  text-align: center;
  width: 100%;

  span {
    position: relative;
    display: inline-block;
  }

  span::before,
  span::after {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 2px solid;
    width: 591px; /* half of limiter*/
    margin: 0 20px;
  }

  span::before {
    right: 100%;
  }

  span:after {
    left: 100%;
  }
`

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    padding: 0;
    padding-top: 1rem;
  }
`

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    padding-left: 0;
    padding-right: 1rem;
  }
`

const MessageContainer = styled.div`
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 16px;

  @media only screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    padding-left: 1rem;
    padding-right: 0;
  }
`

const MessageBox = styled(TextField)`
  width: 100%;
  background: white;
  color: #7b7c7c;
  font-family: 'Roboto';
  border-radius: ${({ theme }) =>
    theme == 'Theme5' ||
    theme == 'Theme6' ||
    theme == 'Theme7' ||
    theme == 'Theme8'
      ? '0px'
      : `4.5px`};
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 4rem;
  align-items: center;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
  }
`

const HeaderText = styled(Typography)``

const SubtitleText = styled(Typography)`
  padding-top: 2rem;
`

const SubmitButton = styled(Button)`
  margin-left: 2rem;
`

const Input = styled(TextField)`
  background: white;
  border-radius: ${({ theme }) =>
    theme == 'Theme5' ||
    theme == 'Theme6' ||
    theme == 'Theme7' ||
    theme == 'Theme8'
      ? '0px'
      : `4.5px`};
  label {
    color: #7b7c7c;
  }
`

const ResetButton = styled(Button)`
  span {
    color: ${({ textColor }) => `${textColor}` || `inherit`};
  }
`

const MessageTextContainer = styled.div`
  color: ${({ color }) => `${color}` || `inherit`};
`
const CheckboxContainer = styled.div`
padding-top: 10px;
padding-left: 10px;
padding-right: 10px;
font-family: 'Roboto';
`;

const Contact1 = ({
  background,
  text,
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  isSharpSpring,
  account,
  formID,
  domain,
  formHeight = 500,
  image,
  btnColor = 'primary',
  btnStyle = 'contained',
  header = 'Contact Us'
}) => {
  const domain_id = useSelector(R.view(idLens))
  const dispatch = useDispatch()
  const iframeSrc = `https://${domain}/prospector/form/${account}/${formID}`
  const [showMessage, setShowMessage] = useState(false)

  const settings = useSelector(state => {
    return R.view(settingsLens, state) || {}
  })

  const handleSubmit = e => {
    e.preventDefault()

    const formdata = new FormData(e.target)

    const data = {
      domain_id,
      status: 'not contacted',
      form_data: {
        name: formdata.get('name'),
        email: formdata.get('email'),
        phone: formdata.get('phone'),
        message: formdata.get('message')
      }
    }

    if (window && window.grecaptcha) {
      try {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(settings.captcha_client, {
              action: 'submit'
            })
            .then(token => {
              submitData(token)
            })
        })
      } catch (e) {
        console.log(e)
      }
    }

    const submitData = token => {
      // call a backend API to verify reCAPTCHA response
      fetch(`${URL}/leads/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'g-recaptcha-response': token,
          domain_id
        })
      })
        .then(res => res.json())
        .then(res => {
          if (res.success && res.score > 0.5) {
            dispatch({
              type: actions.CREATE_LEAD_REQUEST,
              payload: data
            })

            setShowMessage(true)
          }
        })
    }

    /* eslint-disable-next-line */
  }

  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  return (
    <ContactContainer img={image} color={bgColor} onSubmit={handleSubmit}>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${settings.captcha_client}`}
        ></script>
      </Helmet>
      <TextContainer theme={currentThemeEnum} color={textColor}>
        <DecoratedText variant="h2" component="p" color="inherit">
          <span>{header}</span>
        </DecoratedText>
        <SubtitleText variant="body1" color="inherit">
          {subtitle}
        </SubtitleText>
      </TextContainer>
      <FormContainer>
        {isSharpSpring ? (
          <iframe
            style={{ height: `${formHeight}px` }}
            id={`ssf_${formID}`}
            src={iframeSrc}
            frameBorder="0"
          />
        ) : (
          <React.Fragment>
            <InformationContainer>
              <Input
                theme={currentThemeEnum}
                name="name"
                id="name"
                aria-label="Name"
                title="Name"
                label="Name"
                margin="normal"
                required
                variant="outlined"
              />
              <Input
                theme={currentThemeEnum}
                required
                name="email"
                id="email"
                aria-label="Email"
                title="Email"
                label="Email"
                margin="normal"
                variant="outlined"
              />
              <InputMask mask="(999) 999-9999" maskChar=" ">
                {() => (
                  <Input
                    theme={currentThemeEnum}
                    name="phone"
                    id="phone"
                    aria-label="Phone Number"
                    title="Phone"
                    label="Phone"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              </InputMask>
            </InformationContainer>
            <MessageContainer>
              <MessageBox
                theme={currentThemeEnum}
                name="message"
                id="message"
                aria-label="Message"
                title="Message"
                label="Message"
                variant="outlined"
                multiline
                rows={10}
              />

<CheckboxContainer>
                <input
                  type="checkbox"
                  id="subscribeCheckbox"
                  name="subscribe"
                  aria-label="Subscribe to Newsletter"
                  checked={isCheckboxChecked}
                  onChange={handleCheckboxChange}
                />
                <label class="contact-us-checkbox" htmlFor="subscribeCheckbox">By checking this box, I agree that Clayton may contact me by telephone, provide me with marketing communications about Clayton products and services, and share my contact information as described in our website  <a target="_blank" href="https://www.claytonhomes.com/account-terms-conditions/">Terms &amp; Conditions</a> and <a target="_blank" href="https://www.claytonhomes.com/privacy/">Privacy Policy</a>.</label>
              </CheckboxContainer>
              {currentThemeEnum == 'Theme9' && (
                <ButtonContainer
                  style={{
                    justifyContent: 'center',
                    paddingRight: '0px',
                    paddingTop: '15px'
                  }}
                >
                  {showMessage && (
                    <MessageTextContainer color={textColor}>
                      <Typography>
                        Thank you for your submission. One of our sales staff
                        will be in contact with you soon.
                      </Typography>
                    </MessageTextContainer>
                  )}
                  <ResetButton
                    textColor={textColor}
                    onClick={() => setShowMessage(false)}
                    type="reset"
                  >
                    Reset
                  </ResetButton>
                  <SubmitButton
                    disabled={!isCheckboxChecked || showMessage}
                    color={btnColor}
                    type="submit"
                    variant={btnStyle}
                  >
                    Submit
                  </SubmitButton>
                </ButtonContainer>
              )}
            </MessageContainer>
          </React.Fragment>
        )}
      </FormContainer>
      {isSharpSpring || currentThemeEnum == 'Theme9' ? null : (
        <ButtonContainer>
          {showMessage && (
            <MessageTextContainer color={textColor}>
              <Typography>
                Thank you for your submission. One of our sales staff will be in
                contact with you soon.
              </Typography>
            </MessageTextContainer>
          )}
          <ResetButton
            textColor={textColor}
            onClick={() => setShowMessage(false)}
            type="reset"
          >
            Reset
          </ResetButton>
          <SubmitButton
            disabled={!isCheckboxChecked || showMessage}
            color={btnColor}
            type="submit"
            variant={btnStyle}
          >
            Submit
          </SubmitButton>
        </ButtonContainer>
      )}
    </ContactContainer>
  )
}

Contact1.displayName = 'Contact 1'
Contact1.moduleName = 'Contact1'

export default Contact1
