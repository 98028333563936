import React, { useState } from 'react'
import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import * as R from 'ramda'

import ColorPicker from './ColorPicker'
import InputContainer from './Container'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const DescriptionAndColorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
`

const ImageUploadContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const TextAndImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`

const AffiliationsEdit = ({ data, handleEdit }) => {
  const { background = '#ffffff', text = '#222323', text1, text2 } = data

  const [bgImage, setBgImage] = useState(data.bgImage)

  const addItem = () =>
    handleEdit(oldState => ({
      ...oldState,
      affiliationLogos: [
        ...oldState.affiliationLogos,
        { img: '', url: '', alt: '', title: '' }
      ]
    }))

  const updateItem = (index, key, value) =>
    handleEdit(oldState => ({
      ...oldState,
      affiliationLogos: R.adjust(
        index,
        R.mergeLeft({ [key]: value }),
        oldState.affiliationLogos
      )
    }))

  const removeItem = index =>
    handleEdit(oldState => ({
      ...oldState,
      affiliationLogos: R.remove(index, 1, oldState.affiliationLogos)
    }))

  return (
    <InputContainer>
      <InnerContainer>
        <DescriptionAndColorContainer>
          <ColorInputContainer>
            <ColorPicker
              color={background}
              name="background"
              title="Background Color"
            />
            <ColorPicker color={text} name="text" title="Text Color" />
          </ColorInputContainer>
          {/* <Typography align="center">Background Image</Typography>
          <FileUpload name="image-upload" onChange={setFile} file={file} />
          <input hidden value={file} name="file" /> */}
        </DescriptionAndColorContainer>
        <TextField
          name="text1"
          id="text1"
          aria-label="Text Line 1"
          title="Text Line 1"
          label="Text Line 1"
          margin="normal"
          variant="outlined"
          defaultValue={text1}
        />
        <TextField
          name="text2"
          id="text2"
          aria-label="Text Line 2"
          title="Text Line 2"
          label="Text Line 2"
          margin="normal"
          variant="outlined"
          defaultValue={text2}
        />
        {data.affiliationLogos &&
          data.affiliationLogos.map((logo, i) => {
            return (
              <InnerContainer key={i}>
                <HeaderContainer>
                  <InputLabel>Logo {i + 1}</InputLabel>
                  <IconButton onClick={() => removeItem(i)}>
                    <DeleteIcon />
                  </IconButton>
                </HeaderContainer>
                <TextAndImageContainer>
                  <ImageUploadContainer>
                    {/* <FileUpload
                      name={`file-${i}`}
                      onChange={e => updateItem(i, 'path', e)}
                      file={file.path}
                    />
                    <input hidden value={file.path} /> */}
                    <ImageUpload
                      name={`logo-${i}`}
                      onChange={e => updateItem(i, 'img', e)}
                      image={logo.img}
                    />
                    <input hidden value={logo.img} name="image" />
                  </ImageUploadContainer>
                  <TextField
                    name={`url-${i}`}
                    id={`url-${i}`}
                    aria-label="Link URL"
                    title="Link URL"
                    label="Link URL"
                    margin="normal"
                    variant="outlined"
                    onChange={e => updateItem(i, 'url', e.target.value)}
                    defaultValue={logo.url}
                  />
                  <TextField
                    name={`alt-${i}`}
                    id={`alt-${i}`}
                    aria-label="Logo Alt Tag"
                    title="Logo Alt Tag"
                    label="Alt Tag"
                    margin="normal"
                    variant="outlined"
                    onChange={e => updateItem(i, 'alt', e.target.value)}
                    defaultValue={logo.alt}
                  />
                  <TextField
                    name={`title-${i}`}
                    id={`title-${i}`}
                    aria-label="Logo Title Tag"
                    title="Logo Title Tag"
                    label="Title Tag"
                    margin="normal"
                    variant="outlined"
                    onChange={e => updateItem(i, 'title', e.target.value)}
                    defaultValue={logo.title}
                  />
                </TextAndImageContainer>
              </InnerContainer>
            )
          })}
        <Button onClick={addItem}>Add Logo</Button>
      </InnerContainer>
      <Typography align="center">Background Image</Typography>
      <ImageUpload
        name="background-image"
        onChange={setBgImage}
        image={bgImage}
      />
      <input hidden value={bgImage} name="bgImage" />
    </InputContainer>
  )
}

export default AffiliationsEdit
