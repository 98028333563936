import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import ColorPicker from './ColorPicker'
import InputContainer from './Container'

import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const FeaturedImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

const ColorInputWrapper = styled.div`
  padding-right: 2rem;
`

const ColorInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  flex: 1;
  background-color: #f1f2f4;
`

const ImageAndTextContainer = styled.div`
  display: flex;
`

const ImageUploadContainer = styled.div`
  flex: 1;
`

const MortgageCalcEdit = ({ data }) => {
  const { background = '#ffffff', text = '#222323', subtitle } = data
  const [image, setImage] = useState(data.image)

  return (
    <InputContainer>
      <InnerContainer>
        <ImageAndTextContainer>
          <ImageUploadContainer>
            <FeaturedImageContainer>
              <Typography gutterBottom>Featured Image</Typography>
            </FeaturedImageContainer>
            <ImageUpload name="image" onChange={setImage} image={image} />
            <input hidden value={image} name="image" />
          </ImageUploadContainer>
          <TextInputContainer>
            <TextField
              name="subtitle"
              id="subtitle"
              aria-label="Subtitle"
              title="Subtitle"
              label="Subtitle"
              margin="normal"
              variant="outlined"
              defaultValue={subtitle}
              multiline
            />
          </TextInputContainer>
        </ImageAndTextContainer>
        <Typography gutterBottom>Don't have an image?</Typography>
        <ColorInputContainer>
          <ColorInputWrapper>
            <ColorPicker
              color={background}
              name="background"
              title="Background Color"
            />
          </ColorInputWrapper>
          <ColorInputWrapper>
            <ColorPicker color={text} name="text" title="Text Color" />
          </ColorInputWrapper>
        </ColorInputContainer>
      </InnerContainer>
    </InputContainer>
  )
}

export default MortgageCalcEdit
