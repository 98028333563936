import React, { useState, useEffect } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select';
import ColorPicker from './ColorPicker'
import ButtonPicker from './ButtonPicker'
import styled from '@emotion/styled'

import ButtonStylePicker from './ButtonStylePicker'
import InputContainer from './Container'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  display: flex;
`

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem;
`
const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
  flex-direction: column;
`

const PopupWithFormEdit = ({ data, handleEdit }) => {
  const {
    background = '#ffffff',
    text = '#222323',
    content,
    account,
    formID,
    domain,
    formHeight,
    btnColor = 'primary',
    btnStyle = 'contained',
    header,
    popupTime = 1
  } = data

  const [isSharpSpring, setIsSharpSpring] = useState(data.isSharpSpring)
  const [image, setImage] = useState(data.image)

  useEffect(() => {
    handleEdit(oldState => ({
      ...oldState,
      isSharpSpring
    }))
  }, [isSharpSpring])

  return (
    <InputContainer>
      <InnerContainer>
        <TextInputContainer>
          <TextField
            name="header"
            id="header"
            aria-label="Popup Title"
            title="Popup Title"
            label="Popup Title"
            margin="normal"
            variant="outlined"
            defaultValue={header}
          />
          <TextField
            name="content"
            id="content"
            aria-label="Content"
            title="Content"
            label="Popup Content"
            margin="normal"
            variant="outlined"
            defaultValue={content}
            multiline
            rows={10}
          />
          <TextField
            name="popupTime"
            id="popupTime"
            aria-label="Time"
            title="Time"
            label="Time"
            type="number"
            margin="normal"
            variant="outlined"
            defaultValue={popupTime}
          />

<FormControlLabel
            control={
              <Switch
                checked={isSharpSpring}
                onChange={e => setIsSharpSpring(e.target.checked)}
                inputProps={{
                  'aria-label': 'SharpSpring Switch',
                  name: 'isSharpSpring',
                  id: 'isSharpSpring'
                }}
              />
            }
            label="SharpSpring Contact Form"
          />
          {isSharpSpring ? (
            <React.Fragment>
              <TextField
                name="account"
                id="account"
                aria-label="SharpSpring Account"
                title="SharpSpring Account"
                label="SharpSpring Account"
                margin="normal"
                variant="outlined"
                defaultValue={account}
              />
              <TextField
                name="formID"
                id="formID"
                aria-label="SharpSpring Form ID"
                title="SharpSpring Form ID"
                label="SharpSpring Form ID"
                margin="normal"
                variant="outlined"
                defaultValue={formID}
              />
              <TextField
                name="domain"
                id="domain"
                aria-label="SharpSpring Domain"
                title="SharpSpring Domain"
                label="SharpSpring Domain"
                margin="normal"
                variant="outlined"
                defaultValue={domain}
              />
              <TextField
                name="formHeight"
                id="formHeight"
                aria-label="Form Height in Pixels"
                title="Form Height in Pixels"
                label="Form Height in Pixels"
                margin="normal"
                variant="outlined"
                defaultValue={formHeight}
              />
            </React.Fragment>
          ) : null}
        </TextInputContainer>
        <ColorInputContainer>
          <ColorPicker
            color={background}
            name="background"
            title="Background Color"
          />
          <ColorPicker color={text} name="text" title="Text Color" />
          <ButtonPicker color={btnColor} name="btnColor" title="Button Color" />
          <ButtonStylePicker
            style={btnStyle}
            name="btnStyle"
            title="Button Style"
          />
        </ColorInputContainer>
      </InnerContainer>
      <Typography align="center">Main Image</Typography>
      <ImageUpload name="image-upload" onChange={setImage} image={image} />
      <input hidden value={image} name="image" />
    </InputContainer>
  )
}

export default PopupWithFormEdit
