import React, { useState } from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import ColorPicker from './ColorPicker'
import InputContainer from './Container'
import ButtonPicker from './ButtonPicker'
import ButtonStylePicker from './ButtonStylePicker'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const DescriptionAndColorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const DescriptionContainer = styled.div`
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
`

const Header4Edit = ({ data }) => {
  const {
    phone,
    phone2,
    feature,
    background = '#ffffff',
    text = '#222323',
    menuText,
    headerSize = 'h1',
    menu = 'primary',
    menuSubtitle,
    menuSubtitleColor = 'primary',
    orText = 'Or'
  } = data

  const [image, setImage] = useState(data.image)

  return (
    <InputContainer>
      <InnerContainer>
        <DescriptionAndColorContainer>
          <DescriptionContainer>
            <Typography>Phone number:</Typography>
            <TextField
              name="phone"
              id="phone"
              aria-label="Phone"
              title="Phone"
              label="Phone"
              margin="normal"
              variant="outlined"
              defaultValue={phone}
            />
            <TextField
              name="orText"
              id="orText"
              aria-label="Middle Text"
              title="Middle Text"
              label="Middle Text"
              margin="normal"
              variant="outlined"
              defaultValue={orText}
            />
            <TextField
              name="phone2"
              id="phone2"
              aria-label="Other Phone"
              title="Other Phone"
              label="Other Phone"
              margin="normal"
              variant="outlined"
              defaultValue={phone2}
            />
            <TextField
              name="menuSubtitle"
              id="menuSubtitle"
              aria-label="Menu Subtitle"
              title="Menu Subtitle"
              label="Menu Subtitle"
              margin="normal"
              variant="outlined"
              defaultValue={menuSubtitle}
            />
          </DescriptionContainer>
          <ColorInputContainer>
            <ColorPicker
              color={background}
              name="background"
              title="Background Color"
            />
            <ColorPicker
              color={menu}
              name="menu"
              title="Menu Background Color"
            />
            <ColorPicker color={text} name="text" title="Text Color" />
            <ColorPicker color={feature} name="feature" title="Feature Color" />
            <ColorPicker
              color={menuText}
              name="menuText"
              title="Menu Text Color"
            />
            <ColorPicker
              color={menuSubtitleColor}
              name="menuSubtitleColor"
              title="Menu Subtitle Color"
            />
          </ColorInputContainer>
        </DescriptionAndColorContainer>
      </InnerContainer>
      <Typography align="center">Background Image</Typography>
      <ImageUpload name="image" onChange={setImage} image={image} />
      <input hidden value={image} name="image" />
    </InputContainer>
  )
}

export default Header4Edit
