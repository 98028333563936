import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ColorPicker from './ColorPicker'
import ButtonPicker from './ButtonPicker'
import styled from '@emotion/styled'

import ButtonStylePicker from './ButtonStylePicker'
import InputContainer from './Container'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  display: flex;
`

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem;
`
const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
  flex-direction: column;
`

const VideoRequestEdit = ({ data }) => {
  const {
    background = '#ffffff',
    text = '#222323',
    subtitle,
    btnColor = 'primary',
    btnStyle = 'contained',
    header = 'Contact Us'
  } = data

  const [image, setImage] = useState(data.image)

  return (
    <InputContainer>
      <InnerContainer>
        <TextInputContainer>
          <TextField
            name="header"
            id="header"
            aria-label="Header"
            title="Header"
            label="Header"
            margin="normal"
            variant="outlined"
            defaultValue={header}
          />
          <TextField
            name="subtitle"
            id="subtitle"
            aria-label="Subtitle"
            title="Subtitle"
            label="Subtitle"
            margin="normal"
            variant="outlined"
            defaultValue={subtitle}
            multiline
          />
        </TextInputContainer>
        <ColorInputContainer>
          <ColorPicker
            color={background}
            name="background"
            title="Background Color"
          />
          <ColorPicker color={text} name="text" title="Text Color" />
          <ButtonPicker color={btnColor} name="btnColor" title="Button Color" />
          <ButtonStylePicker
            style={btnStyle}
            name="btnStyle"
            title="Button Style"
          />
        </ColorInputContainer>
      </InnerContainer>
      <Typography align="center">Background Image</Typography>
      <ImageUpload name="image-upload" onChange={setImage} image={image} />
      <input hidden value={image} name="image" />
    </InputContainer>
  )
}

export default VideoRequestEdit
