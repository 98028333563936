import React from 'react'
import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import * as R from 'ramda'

import ColorPicker from './ColorPicker'
import InputContainer from './Container'
import FileUpload from '~/SharedComponents/FileUpload'

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const DescriptionAndColorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
`

const ImageUploadContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const TextAndImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`

const FileListEdit = ({ data, handleEdit }) => {
  const { background = '#ffffff', text = '#222323' } = data

  const addItem = () =>
    handleEdit(oldState => ({
      ...oldState,
      files: [...oldState.files, { title: '', path: '' }]
    }))

  const updateItem = (index, key, value) =>
    handleEdit(oldState => ({
      ...oldState,
      files: R.adjust(index, R.mergeLeft({ [key]: value }), oldState.files)
    }))

  const removeItem = index =>
    handleEdit(oldState => ({
      ...oldState,
      files: R.remove(index, 1, oldState.files)
    }))

  return (
    <InputContainer>
      <InnerContainer>
        <DescriptionAndColorContainer>
          <ColorInputContainer>
            <ColorPicker
              color={background}
              name="background"
              title="Background Color"
            />
            <ColorPicker color={text} name="text" title="Text Color" />
          </ColorInputContainer>
          {/* <Typography align="center">Background Image</Typography>
          <FileUpload name="image-upload" onChange={setFile} file={file} />
          <input hidden value={file} name="file" /> */}
        </DescriptionAndColorContainer>
        {data.files &&
          data.files.map((file, i) => {
            return (
              <InnerContainer key={i}>
                <HeaderContainer>
                  <InputLabel>File {i + 1}</InputLabel>
                  <IconButton onClick={() => removeItem(i)}>
                    <DeleteIcon />
                  </IconButton>
                </HeaderContainer>
                <TextAndImageContainer>
                  <ImageUploadContainer>
                    <FileUpload
                      name={`file-${i}`}
                      onChange={e => updateItem(i, 'path', e)}
                      file={file.path}
                    />
                    <input hidden value={file.path} />
                  </ImageUploadContainer>
                  <TextField
                    name={`title-${i}`}
                    id={`title-${i}`}
                    aria-label="File Title"
                    title="File Title"
                    label="File Title"
                    margin="normal"
                    variant="outlined"
                    onChange={e => updateItem(i, 'title', e.target.value)}
                    defaultValue={file.title}
                  />
                </TextAndImageContainer>
              </InnerContainer>
            )
          })}
        <Button onClick={addItem}>Add File</Button>
      </InnerContainer>
    </InputContainer>
  )
}

export default FileListEdit
