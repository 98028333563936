import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import Facebook from '@material-ui/icons/Facebook'
import Twitter from '@material-ui/icons/Twitter'
import Instagram from '@material-ui/icons/Instagram'
import YouTube from '@material-ui/icons/YouTube'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

import BottomFooter from '~/App/BottomFooter'

const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;
`

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`

const MainContainer = styled.div`
  display: flex;
  color: ${({ color }) => color || 'inherit'};
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const LogoContainer = styled(Link)`
  display: flex;
  width: 320px;
  height: 200px;
  align-items: center;
  justify-content: center;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: block;
  }

  @media only screen and (max-width: 768px) {
    width: 160px;
    height: 100px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`

const SocialContainer = styled.div`
  width: 150px;
  margin-top: 5rem;
  display: flex;
  justify-content: center;
`

const CopyrightContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  @media only screen and (max-width: 768px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`

const Address = styled(Typography)``

const PhoneText = styled(Typography)`
  text-align: center;
`

const PoweredByText = styled(Typography)`
  a {
    text-decoration: none;
    color: inherit;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 2rem;
  }
`

const CopyrightText = styled(Typography)`
  display: flex;
  a {
    text-decoration: none;
    color: inherit;
  }

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`

const AddressTag = styled.address`
  font-style: normal;
`

const SocialLink = styled.a`
  flex: 0.25;
`

const LegalLink = styled.a`
  text-decoration: none;
  color: inherit;
`

const Footer2 = ({
  address = '1234 Main St',
  city = 'Anytown',
  state = 'CA',
  zip = 90210,
  phone = '808-555-1234',
  facebook = 'myfacebooklink.com',
  twitter = 'mytwitterlink.com',
  instagram = 'myinstagramlink.com',
  youtube = 'www.youtube.com',
  background,
  text,
  logo = 'https://www.edmition.com/wp-content/uploads/2015/10/generalLogoMEA_175056_300dpi_1772H_1772W.png',
  isPreview,
  image,
  legal,
  terms,
  includeClaytonHomesFooter
}) => {
  const showClaytonHomesFooter = includeClaytonHomesFooter === 'true';

  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const company = useSelector(state => state.profile.company)

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)
  return (
    <FooterContainer img={image} color={bgColor}>
      <MainContainer color={textColor}>
        <LogoContainer to={'/'}>
          {brand.logo && <img src={brand.logo} alt={`${company.name} Logo`} />}
          {!brand.logo && isPreview && (
            <img src={brand.logo || logo} alt={`${company.name} Logo`} />
          )}
        </LogoContainer>
        <SocialContainer>
          {facebook && (
            <SocialLink
              target="_blank"
              rel="noopener noreferrer"
              href={facebook}
            >
              <Facebook color="primary" />
            </SocialLink>
          )}
          {twitter && (
            <SocialLink
              target="_blank"
              rel="noopener noreferrer"
              href={twitter}
            >
              <Twitter color="primary" />
            </SocialLink>
          )}
          {instagram && (
            <SocialLink
              target="_blank"
              rel="noopener noreferrer"
              href={instagram}
            >
              <Instagram color="primary" />
            </SocialLink>
          )}
          {youtube && (
            <SocialLink
              target="_blank"
              rel="noopener noreferrer"
              href={youtube}
            >
              <YouTube color="primary" />
            </SocialLink>
          )}
        </SocialContainer>
        <AddressContainer>
          <AddressTag>
            <Address color="inherit">
              {address} {city} {state} {zip}
            </Address>
            <PhoneText color="inherit">{phone}</PhoneText>
          </AddressTag>
        </AddressContainer>
        <CopyrightContainer>
          <CopyrightText variant="caption" color="inherit">
            Copyright © {new Date().getFullYear()}{' '}
            {legal && <LegalLink href={legal}>&nbsp;Legal</LegalLink>}
            {legal && terms && <p>&nbsp;| </p>}{' '}
            {terms && (
              <LegalLink href={terms}>&nbsp;Terms & Conditions</LegalLink>
            )}
          </CopyrightText>
          <PoweredByText variant="caption" color="inherit">
            <a
              rel="noopener noreferrer"
              href="https://www.gopowerslide.com"
              target="_blank"
            >
              Powered by PowerSlide
            </a>
          </PoweredByText>
        </CopyrightContainer>
      </MainContainer>
      <>
      {showClaytonHomesFooter && <BottomFooter />}
      </>
    </FooterContainer>
  )
}

Footer2.displayName = 'Footer 2'
Footer2.moduleName = 'Footer2'

export default Footer2
