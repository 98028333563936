import React, { useState } from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useSelector, useDispatch } from 'react-redux'
import * as R from 'ramda'
import InputMask from 'react-input-mask'

import { themeLens, brandLens, settingsLens } from '~/State/ducks/profile'
import { actions } from '~/State/ducks/leads'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'
import { idLens } from '~/State/ducks/domain'
import URL from '~/Utils/api_base'

const ContactContainer = styled.form`
  width: 100%;
  display: flex;
  background: ${({ backgroundColor }) => `${backgroundColor}` || `inherit`};

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    background-image: none;
    padding: 0;
  }
`

const TextContainer = styled.div`
  width: 100%;
  padding: 2rem;
  padding-left: 4rem;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  background-image: ${({ img }) => `url(${img})`};
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1;

  box-shadow: ${({ theme }) =>
    theme == 'Theme5' ? '7px 0 5px -2px rgba(0,0,0,0.25)' : 'none'};

  color: ${({ color }) => `${color}` || `inherit`};

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    background-image: ${({ img }) => `url(${img})`};
    background-size: cover;
    padding: 1rem;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    margin-left: 0;
  }
`

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem;
  flex: 1;

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    padding: 0;
    padding-top: 1rem;
  }
`

const InformationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    padding-left: 0;
    padding-right: 1rem;
  }
`

const MessageBox = styled(TextField)`
  width: 100%;
  margin-top: 2rem;
  border-radius: ${({ theme }) =>
    theme == 'Theme5' ||
    theme == 'Theme6' ||
    theme == 'Theme7' ||
    theme == 'Theme8'
      ? '0px'
      : `4.5px`};
  background: white;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
`

const HeaderText = styled(Typography)`
  font-weight: ${({ theme }) =>
    (theme == 'Theme6' || theme == 'Theme7' || theme == 'Theme8') &&
    600} !important;
`

const SubtitleText = styled(Typography)`
  padding-top: 2rem;
`

const SubmitButton = styled(Button)`
  margin-left: 2rem;
`

const MessageContainer = styled.div`
  padding-top: 16px;
`

const Input = styled(TextField)`
  background: white;
  border-radius: ${({ theme }) =>
    theme == 'Theme5' ||
    theme == 'Theme6' ||
    theme == 'Theme7' ||
    theme == 'Theme8'
      ? '0px'
      : `4.5px`};

  label {
    color: #7b7c7c;
  }
`

const ResetButton = styled(Button)`
  span {
    color: ${({ textColor }) => `${textColor}` || `inherit`};
  }
`

const MessageTextContainer = styled.div`
  color: ${({ color }) => `${color}` || `inherit`};
  `
  const CheckboxContainer = styled.div`
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: 'Roboto';
  `;


const Contact3 = ({
  background,
  text,
  image = 'https://huckberry.imgix.net/uploads/post/image/864/hero_XtUb2.jpg?ixlib=rails-2.1.4&auto=compress%2Cformat&cs=tinysrgb&fit=max',
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  isSharpSpring,
  account,
  formID,
  domain,
  formHeight = 500,
  btnColor = 'primary',
  btnStyle = 'contained',
  header = 'Contact Us'
}) => {
  const domain_id = useSelector(R.view(idLens))
  const dispatch = useDispatch()
  const iframeSrc = `https://${domain}/prospector/form/${account}/${formID}`
  const [showMessage, setShowMessage] = useState(false)

  const settings = useSelector(state => {
    return R.view(settingsLens, state) || {}
  })

  const handleSubmit = e => {
    e.preventDefault()

    const formdata = new FormData(e.target)

    const data = {
      domain_id,
      status: 'not contacted',
      form_data: {
        name: formdata.get('name'),
        email: formdata.get('email'),
        phone: formdata.get('phone'),
        message: formdata.get('message')
      }
    }

    if (window && window.grecaptcha) {
      try {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(settings.captcha_client, {
              action: 'submit'
            })
            .then(token => {
              submitData(token)
            })
        })
      } catch (e) {
        console.log(e)
      }
    }

    const submitData = token => {
      // call a backend API to verify reCAPTCHA response
      fetch(`${URL}/leads/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'g-recaptcha-response': token,
          domain_id
        })
      })
        .then(res => res.json())
        .then(res => {
          if (res.success && res.score > 0.5) {
            dispatch({
              type: actions.CREATE_LEAD_REQUEST,
              payload: data
            })

            setShowMessage(true)
          }
        })
    }

    /* eslint-disable-next-line */
  }

  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  return (
    <ContactContainer onSubmit={handleSubmit} backgroundColor={bgColor}>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${settings.captcha_client}`}
        ></script>
      </Helmet>
      <TextContainer theme={currentThemeEnum} img={image} color={textColor}>
        <HeaderText theme={currentThemeEnum} component="p" variant="h2">
          {header}
        </HeaderText>
        <SubtitleText variant="body1">{subtitle}</SubtitleText>
      </TextContainer>
      <FormContainer>
        {isSharpSpring ? (
          <iframe
            style={{ height: `${formHeight}px` }}
            id={`ssf_${formID}`}
            src={iframeSrc}
            frameBorder="0"
          />
        ) : (
          <InformationContainer>
            <Input
              theme={currentThemeEnum}
              name="name"
              id="name"
              aria-label="Name"
              title="Name"
              label="Name"
              margin="normal"
              required
              variant="outlined"
            />
            <Input
              theme={currentThemeEnum}
              name="email"
              id="email"
              aria-label="Email"
              title="Email"
              label="Email"
              margin="normal"
              required
              variant="outlined"
            />
            <InputMask mask="(999) 999-9999" maskChar=" ">
              {() => (
                <Input
                  theme={currentThemeEnum}
                  name="phone"
                  id="phone"
                  aria-label="Phone Number"
                  title="Phone"
                  label="Phone"
                  margin="normal"
                  variant="outlined"
                />
              )}
            </InputMask>
            <MessageContainer>
              <MessageBox
                theme={currentThemeEnum}
                name="message"
                id="message"
                aria-label="Message"
                title="Message"
                label="Message"
                multiline
                variant="outlined"
                rows={6}
              />

<CheckboxContainer>
                <input
                  type="checkbox"
                  id="subscribeCheckbox"
                  name="subscribe"
                  aria-label="Subscribe to Newsletter"
                  checked={isCheckboxChecked}
                  onChange={handleCheckboxChange}
                />
                <label class="contact-us-checkbox" htmlFor="subscribeCheckbox">By checking this box, I agree that Clayton may contact me by telephone, provide me with marketing communications about Clayton products and services, and share my contact information as described in our website  <a target="_blank" href="https://www.claytonhomes.com/account-terms-conditions/">Terms &amp; Conditions</a> and <a target="_blank" href="https://www.claytonhomes.com/privacy/">Privacy Policy</a>.</label>
              </CheckboxContainer>
            </MessageContainer>
            <ButtonContainer>
              {showMessage && (
                <MessageTextContainer color={textColor}>
                  <Typography color="inherit">
                    Thank you for your submission. One of our sales staff will
                    be in contact with you soon.
                  </Typography>
                </MessageTextContainer>
              )}
              <ResetButton
                textColor={textColor}
                onClick={() => setShowMessage(false)}
                type="reset"
              >
                Reset
              </ResetButton>
              <SubmitButton
                color={btnColor}
                disabled={!isCheckboxChecked || showMessage}
                type="submit"
                variant={btnStyle}
              >
                Submit
              </SubmitButton>
            </ButtonContainer>
          </InformationContainer>
        )}
      </FormContainer>
    </ContactContainer>
  )
}

Contact3.displayName = 'Contact 3'
Contact3.moduleName = 'Contact3'

export default Contact3
