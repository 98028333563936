import React, { useState } from 'react'
import styled from '@emotion/styled'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ColorPicker from './ColorPicker'
import ButtonPicker from './ButtonPicker'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import ButtonStylePicker from './ButtonStylePicker'
import InputContainer from './Container'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  display: flex;
`

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

const CallToActionText = styled(Typography)`
  margin-top: 2rem;
`

const Intro1Edit = ({ data }) => {
  const {
    background = '#ffffff',
    text = '#222323',
    btnColor = 'primary',
    btnStyle = 'contained',
    headerSize = 'h2'
  } = data

  const [image, setImage] = useState(data.image)
  const [headerFontSize, setHeaderFontSize] = useState(headerSize)

  return (
    <InputContainer>
      <InnerContainer>
        <TextInputContainer>
          <TextField
            name="header"
            id="header"
            aria-label="Header"
            title="Header"
            label="Header"
            margin="normal"
            variant="outlined"
            defaultValue={data.header}
          />
          <FormControl>
            <InputLabel id="header-size">Header Size</InputLabel>
            <Select
              name="headerSize"
              labelId="header-size"
              id="header-size-select"
              value={headerFontSize}
              onChange={e => setHeaderFontSize(e.target.value)}
            >
              <MenuItem value="h1">H1</MenuItem>
              <MenuItem value="h2">H2</MenuItem>
              <MenuItem value="h3">H3</MenuItem>
              <MenuItem value="h4">H4</MenuItem>
              <MenuItem value="h5">H5</MenuItem>
              <MenuItem value="h6">H6</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name="subtitle"
            id="subtitle"
            aria-label="Subtitle"
            title="Subtitle"
            label="Subtitle"
            margin="normal"
            variant="outlined"
            defaultValue={data.subtitle}
            multiline
          />
          <CallToActionText>Call to Action Button</CallToActionText>
          <TextField
            name="ctaText"
            id="ctaText"
            aria-label="Call to Action Text"
            title="Call to Action Text"
            label="Call to Action Text"
            margin="normal"
            variant="outlined"
            defaultValue={data.ctaText}
          />
          <TextField
            name="ctaLink"
            id="ctaLink"
            aria-label="Call to Action Link"
            title="Call to Action Link"
            label="Call to Action Link"
            margin="normal"
            variant="outlined"
            defaultValue={data.ctaLink}
          />
        </TextInputContainer>
        <ColorInputContainer>
          <ColorPicker
            color={background}
            name="background"
            title="Background Color"
          />
          <ColorPicker color={text} name="text" title="Text Color" />
          <ButtonPicker color={btnColor} name="btnColor" title="Button Color" />
          <ButtonStylePicker
            style={btnStyle}
            name="btnStyle"
            title="Button Style"
          />
        </ColorInputContainer>
      </InnerContainer>
      <Typography align="center">Background Image</Typography>
      <ImageUpload name="image" onChange={setImage} image={image} />
      <input hidden value={image} name="image" />
    </InputContainer>
  )
}

export default Intro1Edit
