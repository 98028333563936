import React, { useState } from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ImageUpload from '~/SharedComponents/ImageUpload'

import ColorPicker from './ColorPicker'

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const DescriptionAndColorContainer = styled.div`
  width: 100%;
  display: flex;
`

const DescriptionContainer = styled.div`
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
`

const AddressAndSocialContainer = styled.div`
  width: 100%;
  display: flex;
`

const AddressInputContainer = styled.div`
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const SocialInputContainer = styled.div`
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`

import InputContainer from './Container'

const Footer1Edit = ({ data, handleEdit }) => {
  const {
    background = '#ffffff',
    text = '#222323',
    feature,
    menuText,
    featureText,
    legal,
    terms
  } = data

  const initialCheckboxState = data.includeClaytonHomesFooter === 'true';
  const [includeClaytonHomesFooter, setIncludeClaytonHomesFooter] = useState(initialCheckboxState);

  const handleCheckboxChange = (event) => {
    setIncludeClaytonHomesFooter(event.target.checked);
    handleEdit(prevState => ({
      ...prevState,
      includeClaytonHomesFooter: event.target.checked ? 'true' : 'false'
    }));
  };


  const [image, setImage] = useState(data.image)

  return (
    <InputContainer>
      <InnerContainer>
        <DescriptionAndColorContainer>
          <DescriptionContainer>
            <Typography>Headline Text</Typography>
            <TextField
              name="line1"
              id="line1"
              aria-label="Line 1"
              title="Line 1"
              label="Line 1"
              margin="normal"
              variant="outlined"
              defaultValue={data.line1}
            />
            <TextField
              name="line2"
              id="line2"
              aria-label="Line 2"
              title="Line 2"
              label="Line 2"
              margin="normal"
              variant="outlined"
              defaultValue={data.line2}
            />
          </DescriptionContainer>
          <ColorInputContainer>
            <ColorPicker
              color={background}
              name="background"
              title="Background Color"
            />
            <ColorPicker color={text} name="text" title="Text Color" />
            <ColorPicker color={feature} name="feature" title="Feature Color" />
            <ColorPicker
              color={menuText}
              name="menuText"
              title="Menu Text Color"
            />
            <ColorPicker
              color={featureText}
              name="featureText"
              title="Feature Text Color"
            />
          </ColorInputContainer>
        </DescriptionAndColorContainer>
        <AddressAndSocialContainer>
          <AddressInputContainer>
            <Typography>Address:</Typography>
            <TextField
              name="address"
              id="address"
              aria-label="Address"
              title="Address"
              label="Address"
              margin="normal"
              variant="outlined"
              defaultValue={data.address}
            />
            <TextField
              name="city"
              id="city"
              aria-label="City"
              title="City"
              label="City"
              margin="normal"
              variant="outlined"
              defaultValue={data.city}
            />
            <TextField
              name="state"
              id="state"
              aria-label="State"
              title="State"
              label="State"
              margin="normal"
              variant="outlined"
              defaultValue={data.state}
            />
            <TextField
              name="zip"
              id="zip"
              aria-label="Zip"
              title="Zip"
              label="Zip"
              margin="normal"
              variant="outlined"
              defaultValue={data.zip}
            />
            <TextField
              name="phone"
              id="phone"
              aria-label="Phone"
              title="Phone"
              label="Phone"
              margin="normal"
              variant="outlined"
              defaultValue={data.phone}
            />
          </AddressInputContainer>
          <SocialInputContainer>
            <Typography>Social Platforms</Typography>
            <TextField
              name="facebook"
              id="facebook"
              aria-label="Facebook Link"
              title="Facebook Link"
              label="Facebook Link"
              margin="normal"
              variant="outlined"
              defaultValue={data.facebook}
            />
            <TextField
              name="twitter"
              id="twitter"
              aria-label="Twitter Link"
              title="Twitter Link"
              label="Twitter Link"
              margin="normal"
              variant="outlined"
              defaultValue={data.twitter}
            />
            <TextField
              name="instagram"
              id="instagram"
              aria-label="Instagram Link"
              title="Instagram Link"
              label="Instagram Link"
              margin="normal"
              variant="outlined"
              defaultValue={data.instagram}
            />
            <TextField
              name="youtube"
              id="youtube"
              aria-label="YouTube Link"
              title="YouTube Link"
              label="YouTube Link"
              margin="normal"
              variant="outlined"
              defaultValue={data.youtube}
            />
          </SocialInputContainer>
        </AddressAndSocialContainer>
      </InnerContainer>
      <AddressInputContainer>
        <Typography>Legal and Terms & Conditions Links</Typography>
        <TextField
          name="legal"
          id="legal"
          aria-label="Legal Link"
          title="Legal Link"
          label="Legal Link"
          margin="normal"
          variant="outlined"
          defaultValue={legal}
        />
        <TextField
          name="terms"
          id="terms"
          aria-label="Terms & Conditions Link"
          title="Terms & Conditions Link"
          label="Terms & Conditions Link"
          margin="normal"
          variant="outlined"
          defaultValue={terms}
        />
      </AddressInputContainer>
      <Typography align="center">Background Image</Typography>
      <ImageUpload name="image-upload" onChange={setImage} image={image} />
      <input hidden value={image} name="image" />
      <FormControlLabel
        control={
          <Checkbox
            checked={includeClaytonHomesFooter}
            onChange={handleCheckboxChange}
            name="includeClaytonHomesFooter"
          />
        }
        label="Include Clayton Homes footer"
      />
    </InputContainer>
  )
}

export default Footer1Edit
