import React, { useState, useEffect } from 'react'; 
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import '~/App/global.css';


const useStyles = makeStyles((theme) => ({
  customSwitch: {
    '&$checked': {
      color: '#37cd8f', // Color when checked
    },
  },
  checked: {},
}));

// Function to set a cookie
const setCookie = (name, value, days) => {
  const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
};

// Function to retrieve a cookie by name
const getCookie = (name) => {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
};

const CookieStorage = () => {
  const classes = useStyles(); 
  // Create state variables for each toggle switch
  const [strictlyNecessary, setStrictlyNecessary] = useState(true);
  const [marketing, setMarketing] = useState(true);
  const [personalization, setPersonalization] = useState(true);
  const [analytics, setAnalytics] = useState(true);
  const [doNotSell, setDoNotSell] = useState(true);

  // Function to handle toggle state changes
  const handleToggleChange = (toggleName) => {
    switch (toggleName) {
      case 'Strictly Necessary Cookies':
        setStrictlyNecessary(!strictlyNecessary);
        break;
      case 'Marketing':
        setMarketing(!marketing);
        break;
      case 'Personalization':
        setPersonalization(!personalization);
        break;
      case 'Analytics':
        setAnalytics(!analytics);
        break;
      case 'Do Not Sell or Share My Personal Information':
        setDoNotSell(!doNotSell);
        break;
      default:
        break;
    }
  };

  // Save the toggle states to cookies when they change
  useEffect(() => {
    setCookie('strictlyNecessary', strictlyNecessary.toString(), 365);
    setCookie('marketing', marketing.toString(), 365);
    setCookie('personalization', personalization.toString(), 365);
    setCookie('analytics', analytics.toString(), 365);
    setCookie('doNotSell', doNotSell.toString(), 365);
  }, [strictlyNecessary, marketing, personalization, analytics, doNotSell]);

  // Retrieve toggle states from cookies when the component mounts
  useEffect(() => {
    const savedStrictlyNecessary = getCookie('strictlyNecessary');
    const savedMarketing = getCookie('marketing');
    const savedPersonalization = getCookie('personalization');
    const savedAnalytics = getCookie('analytics');
    const savedDoNotSell = getCookie('doNotSell');

    if (savedStrictlyNecessary !== null) {
      setStrictlyNecessary(savedStrictlyNecessary === 'true');
    }
    if (savedMarketing !== null) {
      setMarketing(savedMarketing === 'true');
    }
    if (savedPersonalization !== null) {
      setPersonalization(savedPersonalization === 'true');
    }
    if (savedAnalytics !== null) {
      setAnalytics(savedAnalytics === 'true');
    }
    if (savedDoNotSell !== null) {
      setDoNotSell(savedDoNotSell === 'true');
    }
  }, []);


  return (
    <div>
      <ul className="cookie-content">
      <li class="cookie-header">Cookie Header</li>
      <li class="cookie-text">When you visit websites, they may store or retrieve data about you using cookies and similar technologies ("cookies"). Cookies may be necessary for the basic functionality of the website as well as other purposes. You have the option of disabling certain types of cookies, though doing so may impact your experience on the website.
      </li>
      <li class="toggle-item"> {/* Add the class toggle-item */}
          Strictly Necessary Cookies
          <div className="toggle-right">
          <Switch
            checked={strictlyNecessary}
            onChange={() => handleToggleChange('Strictly Necessary Cookies')}      
          />
          </div>
        </li>
      <li class="cookie-text">These cookies, also sometimes referred to as “essential,” allow the page to load or provide some essential functionality without which the page would not work.</li>
        <li class="toggle-item">
          Marketing
          <div className="toggle-right">
          <Switch
            checked={marketing}
            onChange={() => handleToggleChange('Marketing')}
          />
          </div>
        </li>
      <li class="cookie-text">These cookies are utilized to provide advertising content tailored to your interests. They are designed to make advertising more relevant to you and may limit the frequency with which you encounter specific advertisements. Moreover, they are used to assess the effectiveness of advertising campaigns. Typically, these marketing cookies are placed by advertising networks with the consent of the website operator.</li>
        <li class="toggle-item">
          Personalization
          <div className="toggle-right">
          <Switch
            checked={personalization}
            onChange={() => handleToggleChange('Personalization')}
          />
          </div>
        </li>
      <li class="cookie-text">Allow the website to remember choices you make (such as your username, language, or the region you are in) and provide enhanced, more personal features. For example, a website may provide you with local weather reports or traffic news by storing data about your general location.</li>
        <li class="toggle-item">
          Analytics
          <div className="toggle-right">
          <Switch
            checked={analytics}
            onChange={() => handleToggleChange('Analytics')}
          />
          </div>
        </li>
      <li class="cookie-text">These cookies tell us how our websites are working, how visitors interact with our websites, and whether there may be technical issues. This storage type usually doesn’t collect information that identifies a visitor.</li>
        <li class="toggle-item">
        Do Not Sell or Share My Personal Information
          <div className="toggle-right">
          <Switch
            checked={doNotSell}
            onChange={() => handleToggleChange('Do Not Sell or Share My Personal Information')}
          />
          </div>
        </li>
      <li>We disclose personal information as described in our privacy policy. Some of these disclosures may be considered a "sharing" of personal information for "cross-context behavioral advertising," or "targeted advertising," or a "sale" of personal information under relevant law. Based on where you live, you may have the right to opt out of these disclosures and targeted advertising. Opting out of these disclosures means that you will still see ads, but they will be less relevant to you, and you may see the same ads more frequently. Please note that if you use different computers or browsers, you must indicate your choices again on each computer/browser used to access our services.</li>
      
      </ul>
    </div>
  );
};

export default CookieStorage;
