import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import * as R from 'ramda'

import ColorPicker from './ColorPicker'
import InputContainer from './Container'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
`

const Reviews2Edit = ({ data, handleEdit }) => {
  const { background = '#ffffff', text = '#222323', header } = data

  const [image, setImage] = useState(data.image)

  const addItem = () =>
    handleEdit(oldState => ({
      ...oldState,
      reviews: [
        ...oldState.reviews,
        { content: '', name: '', location: '', subtitle: '' }
      ]
    }))

  const updateItem = (index, key, value) =>
    handleEdit(oldState => ({
      ...oldState,
      reviews: R.adjust(index, R.mergeLeft({ [key]: value }), oldState.reviews)
    }))

  const removeItem = index =>
    handleEdit(oldState => ({
      ...oldState,
      reviews: R.remove(index, 1, oldState.reviews)
    }))

  return (
    <InputContainer>
      <InnerContainer>
        <TextField
          name="header"
          id="header"
          aria-label="Header Text"
          title="Header Text"
          label="Header Text"
          margin="normal"
          variant="outlined"
          defaultValue={header}
        />
        {data.reviews &&
          data.reviews.map((review, i) => {
            return (
              <InnerContainer key={i}>
                <HeaderContainer>
                  <InputLabel>Review {i + 1}</InputLabel>
                  <IconButton onClick={() => removeItem(i)}>
                    <DeleteIcon />
                  </IconButton>
                </HeaderContainer>
                <TextInputContainer>
                  <TextField
                    name={`review-${i}`}
                    id={`review-${i}`}
                    aria-label="Review"
                    title="Review"
                    label="Review"
                    margin="normal"
                    variant="outlined"
                    onChange={e => updateItem(i, 'content', e.target.value)}
                    multiline
                    rows={10}
                    defaultValue={review.content}
                  />
                  <TextField
                    name={`name-${i}`}
                    id={`name-${i}`}
                    aria-label="Name of Person"
                    title="Name of Person"
                    label="Name of Person"
                    margin="normal"
                    onChange={e => updateItem(i, 'name', e.target.value)}
                    variant="outlined"
                    defaultValue={review.name}
                  />
                  <TextField
                    name={`city-${i}`}
                    id={`city-${i}`}
                    aria-label="Location of Person"
                    title="Location of Person"
                    label="Location of Person"
                    margin="normal"
                    variant="outlined"
                    onChange={e => updateItem(i, 'location', e.target.value)}
                    defaultValue={review.location}
                  />
                  <TextField
                    name={`subtitle-${i}`}
                    id={`subtitle-${i}`}
                    aria-label="Subtitle"
                    title="Subtitle"
                    label="Subtitle"
                    margin="normal"
                    variant="outlined"
                    onChange={e => updateItem(i, 'subtitle', e.target.value)}
                    defaultValue={review.subtitle}
                    multiline
                  />
                </TextInputContainer>
              </InnerContainer>
            )
          })}
        <Button onClick={addItem}>Add Review</Button>
        <ColorInputContainer>
          <ColorPicker
            color={background}
            name="background"
            title="Background Color"
          />
          <ColorPicker color={text} name="text" title="Text Color" />
        </ColorInputContainer>
      </InnerContainer>
      <Typography align="center">Background Image</Typography>
      <ImageUpload name="image-upload" onChange={setImage} image={image} />
      <input hidden value={image} name="image" />
    </InputContainer>
  )
}

export default Reviews2Edit
