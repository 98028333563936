import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useSelector, useDispatch } from 'react-redux'
import * as R from 'ramda'
import InputMask from 'react-input-mask'

import { themeLens, brandLens, settingsLens } from '~/State/ducks/profile'
import { actions } from '~/State/ducks/leads'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'
import { idLens } from '~/State/ducks/domain'
import URL from '~/Utils/api_base'

const Model = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1000;
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(49, 49, 49, 0.8);
`

const ModelPopup = styled.div`
  position: absolute;
  margin-top: 10%;
  top: 32%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 0;
  border-radius: 11px;
  max-width: 80%;
  min-width: 50%;
  max-height: 90vh;
  border: 2px solid rgba(0, 0, 0, 0.8);
  box-shadow: 1px 1px 20px grey;
  overflow: hidden auto;
`

const CloseModelButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 7px 4px;
  border-top-right-radius: 9px;
  background-color: #6d6d6d;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  height: 30px;
  width: 40px;
  color: white;
  font-weight: bolder;
  font-size: 1.5rem;
  cursor: pointer;
`

const ModalContent = styled.div``

const PopupWithFormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => `url(${img})`};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
`

const TextContainer = styled.div`
  display: flex;
  color: ${({ color }) => `${color}` || `inherit`};
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme === 'Theme9' && `width: 100%`};
`

const PhotoContainer = styled.div`
  max-width: 480px;
  margin-bottom: 1rem;

  img {
    width: 100%;
    margin: auto;
    box-shadow: ${({ boxShadow = 'inherit' }) => boxShadow};
    padding: ${({ imagePadding }) => imagePadding};
    background: ${({ theme }) =>
      theme == 'Theme3' ||
      theme == 'Theme4' ||
      theme == 'Theme5' ||
      theme == 'Theme6' ||
      theme == 'Theme7' ||
      theme == 'Theme8'
        ? '#ffffff'
        : 'transparent'};
  }
`

const DecoratedText = styled(Typography)`
  overflow: hidden;
  text-align: center;
  width: 100%;

  span {
    position: relative;
    display: inline-block;
  }

  span::before,
  span::after {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 2px solid;
    width: 591px; /* half of limiter*/
    margin: 0 20px;
  }

  span::before {
    right: 100%;
  }

  span:after {
    left: 100%;
  }
`

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    padding: 0;
    padding-top: 1rem;
  }
`

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    padding-left: 0;
    padding-right: 1rem;
  }
`

const MessageBox = styled(TextField)`
  width: 100%;
  background: white;
  color: #7b7c7c;
  font-family: 'Roboto';
  margin-top: 16px !important;
  border-radius: ${({ theme }) =>
    theme == 'Theme5' ||
    theme == 'Theme6' ||
    theme == 'Theme7' ||
    theme == 'Theme8'
      ? '0px'
      : `4.5px`};
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 4rem;
  align-items: center;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
  }
`

const ButtonContainer2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-right: 4rem;
  align-items: center;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
  }
`
const CloseModelButton2 = styled(Button)`
  margin-top: 20px !important;
  margin-bottom: -20px !important;
  span {
    color: ${({ textColor }) => `${textColor}` || `inherit`};
  }
`

const SubtitleText = styled(Typography)`
  padding-top: 2rem;
`

const SubmitButton = styled(Button)`
  margin-left: 2rem;
`

const Input = styled(TextField)`
  background: white;
  border-radius: ${({ theme }) =>
    theme == 'Theme5' ||
    theme == 'Theme6' ||
    theme == 'Theme7' ||
    theme == 'Theme8'
      ? '0px'
      : `4.5px`};
  label {
    color: #7b7c7c;
  }
`

const ResetButton = styled(Button)`
  span {
    color: ${({ textColor }) => `${textColor}` || `inherit`};
  }
`

const MessageTextContainer = styled.div`
  color: ${({ color }) => `${color}` || `inherit`};
`

const PopupWithForm = ({
  background,
  text,
  imageAlt,
  imageTitle,
  image = 'https://huckberry.imgix.net/uploads/post/image/864/hero_XtUb2.jpg?ixlib=rails-2.1.4&auto=compress%2Cformat&cs=tinysrgb&fit=max',
  /* img = 'https://web.archive.org/web/20200405072553if_/http://www.inmanmanufacturedhomes.com/assets/SEExterior_7A2DDA804D737CD78C7098CF91FE68D0_41PRF32643AH.jpg', */
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do carnitas eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  content = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  isSharpSpring,
  account,
  formID,
  domain,
  formHeight = 500,
  img,
  btnColor = 'primary',
  btnStyle = 'contained',
  header = 'Popup with Form',
  isPreviewed = false,
  isAddModule = false,
  popupTime = 1,
  isLive = false
}) => {
  const domain_id = useSelector(R.view(idLens))
  const dispatch = useDispatch()
  const iframeSrc = `https://${domain}/prospector/form/${account}/${formID}`
  const [showMessage, setShowMessage] = useState(false)

  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  const settings = useSelector(state => {
    return R.view(settingsLens, state) || {}
  })

  const handleSubmit = e => {
    e.preventDefault()

    const formdata = new FormData(e.target)

    const data = {
      domain_id,
      status: 'not contacted',
      form_data: {
        name: formdata.get('name'),
        email: formdata.get('email'),
        phone: formdata.get('phone'),
        message: formdata.get('message')
      }
    }

    if (window && window.grecaptcha) {
      try {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(settings.captcha_client, {
              action: 'submit'
            })
            .then(token => {
              submitData(token)
            })
        })
      } catch (e) {
        console.log(e)
      }
    }

    const submitData = token => {
      // call a backend API to verify reCAPTCHA response
      fetch(`${URL}/leads/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'g-recaptcha-response': token,
          domain_id
        })
      })
        .then(res => res.json())
        .then(res => {
          if (res.success && res.score > 0.5) {
            dispatch({
              type: actions.CREATE_LEAD_REQUEST,
              payload: data
            })

            setShowMessage(true)
          }
        })
    }

    /* eslint-disable-next-line */
  }

  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  useEffect(() => {
    ;((isPreviewed && !isAddModule) || isLive) &&
      setTimeout(() => {
        toggleModal()
      }, Number(popupTime) * 1000)
  }, [])

  const renderPopup = () => (
    <PopupWithFormContainer img={img} color={bgColor} onSubmit={handleSubmit}>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${settings.captcha_client}`}
        ></script>
      </Helmet>
      <TextContainer theme={currentThemeEnum} color={textColor}>
        <DecoratedText variant="h2" component="p" color="inherit">
          <span>{header}</span>
        </DecoratedText>

        <PhotoContainer theme={currentThemeEnum} imagePadding={'10px'}>
          <img src={image} alt={imageAlt} title={imageTitle} />
        </PhotoContainer>
        <SubtitleText variant="body1" color="inherit">
          {content}
        </SubtitleText>
      </TextContainer>
      <FormContainer>
        {isSharpSpring ? (
          <iframe
            style={{ height: `${formHeight}px` }}
            id={`ssf_${formID}`}
            src={iframeSrc}
            frameBorder="0"
          />
        ) : (
          <React.Fragment>
            <InformationContainer>
              <Input
                theme={currentThemeEnum}
                name="name"
                id="name"
                aria-label="Name"
                title="Name"
                label="Name"
                margin="normal"
                required
                variant="outlined"
              />
              <Input
                theme={currentThemeEnum}
                required
                name="email"
                id="email"
                aria-label="Email"
                title="Email"
                label="Email"
                margin="normal"
                variant="outlined"
              />
              <InputMask mask="(999) 999-9999" maskChar=" ">
                {() => (
                  <Input
                    theme={currentThemeEnum}
                    name="phone"
                    id="phone"
                    aria-label="Phone Number"
                    title="Phone"
                    label="Phone"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              </InputMask>

              <MessageBox
                theme={currentThemeEnum}
                name="message"
                id="message"
                aria-label="Message"
                title="Message"
                label="Message"
                variant="outlined"
                multiline
                rows={10}
              />
              {currentThemeEnum == 'Theme9' && (
                <ButtonContainer
                  style={{
                    justifyContent: 'center',
                    paddingRight: '0px',
                    paddingTop: '15px'
                  }}
                >
                  {showMessage && (
                    <MessageTextContainer color={textColor}>
                      <Typography>
                        Thank you for your submission. One of our sales staff
                        will be in contact with you soon.
                      </Typography>
                    </MessageTextContainer>
                  )}
                  <ResetButton
                    textColor={textColor}
                    onClick={() => setShowMessage(false)}
                    type="reset"
                  >
                    Reset
                  </ResetButton>
                  <SubmitButton
                    disabled={showMessage}
                    color={btnColor}
                    type="submit"
                    variant={btnStyle}
                  >
                    Submit
                  </SubmitButton>
                </ButtonContainer>
              )}
            </InformationContainer>
          </React.Fragment>
        )}
      </FormContainer>
      {isSharpSpring || currentThemeEnum == 'Theme9' ? null : (
        <ButtonContainer>
          {showMessage && (
            <MessageTextContainer color={textColor}>
              <Typography>
                Thank you for your submission. One of our sales staff will be in
                contact with you soon.
              </Typography>
            </MessageTextContainer>
          )}
          <ResetButton
            textColor={textColor}
            onClick={() => setShowMessage(false)}
            type="reset"
          >
            Reset
          </ResetButton>
          <SubmitButton
            disabled={showMessage}
            color={btnColor}
            type="submit"
            variant={btnStyle}
          >
            Submit
          </SubmitButton>
        </ButtonContainer>
      )}
      <CloseModelButton2 onClick={toggleModal}>Close</CloseModelButton2>
    </PopupWithFormContainer>
  )

  if ((isPreviewed && !isAddModule) || isLive) {
    return (
      <>
        {modal && (
          <>
            <Model>
              <Overlay onClick={toggleModal} />{' '}
              <ModelPopup>
                <ModalContent>
                  {renderPopup()}
                  <CloseModelButton onClick={toggleModal}>x</CloseModelButton>
                </ModalContent>
              </ModelPopup>
            </Model>
          </>
        )}
      </>
    )
  } else {
    return renderPopup()
  }
}

PopupWithForm.displayName = 'Popup With Form'
PopupWithForm.moduleName = 'PopupWithForm'

export default PopupWithForm
